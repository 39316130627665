import React, { useEffect, useMemo, useState } from "react";
import Button from "components/Button";
import { Template } from "layouts/Template";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MathGame from "pages/home/components/MathPage";
import Finish from "pages/home/components/Finish";
import {
  useAddDiagnostiqueEleveTentativeMutation,
  useGetEpreuvexByDiagnostiqueQuery,
} from "redux/features/diagnostiqueApi";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetDiagnostiqueWithNiveauxQuery } from "redux/features/parentApi";
import { useDispatch } from "react-redux";
import {
  setEpreuveNote,
  setEscoreNiveau,
} from "redux/features/diagnostiqueSlice";
import useQuery from "components/useQuery";
import { useSelector } from "react-redux";
import { useGetEleveQuery } from "redux/features/eleveApi";
import { progressImage } from "utils/images";
import Loader from "components/Loader";
import { getNumber } from "utils/soted";
import { compareText, correctFigure } from "utils/comparentText";
import { getCurrentEleve, setTentatives } from "redux/features/eleveSlice";
import { toast } from "react-toastify";
import { arraysAreEqual } from "utils/Array";
import * as mathjs from 'mathjs';

function ExerciceMathPage() {
  const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const searchParams = useQuery();

  const diagnostiqueStore = useSelector(
    (state) => state?.user?.diagnostiqueReducer
  );

  const studentDemo = useSelector(getCurrentEleve);

  // useEffect(() => {
  //   console.log({ diagnostiqueStore });
  // }, []);

  const dispatch = useDispatch();

  const { csrfToken } = useGetUserInfo();

  const { data: datas } = useGetEpreuvexByDiagnostiqueQuery(
    parseInt(params.id)
  );

  const { data: eleve } = useGetEleveQuery(params?.eleveId);

  const { data: diagnostiqueNiveauxData, isLoading } =
    useGetDiagnostiqueWithNiveauxQuery(parseInt(params.id));
  const [diagnostiqueNiveaux, setDiagnostiqueNiveaux] = useState({});
  // console.log(diagnostiqueNiveauxData, "ddddddddddddddddddd")
  const [addDiagnostiqueEleveTentative, { isLoading: addLoading }] =
    useAddDiagnostiqueEleveTentativeMutation();

  const [currentLevel, setCurrentLevel] = useState(
    parseInt(searchParams.get("niveau")) ?? 0
  );

  const [current, setCurrent] = useState(
    parseInt(searchParams.get("epreuve")) ?? 0
  );

  const [selected, setSelected] = useState([]);
  const [end, setEnd] = useState(false);
  const [userAnswer, setUserAnswer] = useState(null);
  const [result] = useState(false);
  const [textRecorded, setTextRecorded] = useState("");

  const [selecteFigure, onSelecteFigure] = useState("");

  /**
   * Ajout de l'element cliquer dans le tableau
   * @param {*} value
   */
  const handleAddWord = (value) => {
    if (!selected.includes(value)) {
      setSelected([...selected, value])
    } else {
      setSelected(selected.filter((item) => item !== value))
    }
    if (diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.type === "od") {
      setUserAnswer([...selected, value]); // Store the updated selection in userAnswer
    }
  };

  useEffect(() => {
    if (diagnostiqueNiveaux.data) {
      console.log('====================================');
      console.log({ selected });
      console.log({ completDiagnostic: diagnostiqueNiveaux.data });
      const levelExercices = diagnostiqueNiveaux?.data[currentLevel]?.data
      console.log({ levelExercices });
      // console.log({ currentExercice: levelExercices[current] });

      console.log({ diagnostiqueNiveaux });
      console.log('====================================');
    }
  }, [selected?.length, diagnostiqueNiveaux]);

  /**
   *
   * @param {string} value
   */
  const handleRemoveWord = (value) => {
    selected.includes(value) &&
      setSelected(selected.filter((item) => item !== value));
  };

  const resetAnswers = () => {
    setUserAnswer(null);
    setSelected([]);
    setTextRecorded("");
    onSelecteFigure([]);
  };

  /**
   * Comparaison de deux tableaux
   * @param {*} tableau1
   * @param {*} tableau2
   * @returns
   */
  function isEqual(tableau1, tableau2) {
    if (tableau1?.length !== tableau2?.length) return false;

    return tableau1.every((value, index) => value === tableau2[index]);
  }

  /**
   * Go next exercice
   * @returns
   */

  const userAnswerAsStrings = userAnswer?.map(String);
  console.log(userAnswerAsStrings);
  const handleNext = async () => {
    const currentExercise =
      diagnostiqueNiveaux?.data[currentLevel]?.data[current];
    const correctAnswer = currentExercise?.correction_dp;

    let note = 0;
    //  reset the answers
    resetAnswers();
    // WIN : Word in Number
    // WIL : Word in letter
    if (currentExercise?.type === "win" || currentExercise?.type === "wil") {
      note = compareText(
        textRecorded.split(",").join(" ").toLowerCase(),
        currentExercise.correction_dp.join(" ").toLowerCase(),
        currentExercise.bareme
      )?.score;
    } else if (currentExercise?.type === "figure") {
      // return console.log({selecteFigure});

      note = correctFigure(
        selecteFigure.join(" "),
        currentExercise?.correction_dp.join(" "),
        currentExercise?.bareme
      );
    } else if (currentExercise?.type === "od" || currentExercise?.type === "oc") {
      // Convert userAnswer to strings for comparison
      const userAnswerAsStrings = userAnswer?.map(String);

      // Check for empty answers
      if (userAnswerAsStrings.includes("")) {
        toast.warning("Veuillez compléter tous les champs de réponse."); // Show warning toast in French
        return; // Exit the function if there are empty answers
      }

      const isCorrect = arraysAreEqual(userAnswerAsStrings, correctAnswer);

      if (isCorrect) {
        note = currentExercise?.bareme; // Give full score if the answer is correct
        console.log("Answer is Correct. Note:", note);
      }
      else {
        console.log("Answer is Incorrect.");
      }
    } else if (currentExercise?.type === "wordToNumbers" || currentExercise?.type === "numberToWords" || currentExercise?.type === "numberDictation" || currentExercise?.type === "completerEnonces") {

      if (Array.isArray(userAnswer) && userAnswer.length > 0) {
        const isCorrect = userAnswer.every(
          (answer, index) => String(answer).toLowerCase() === String(correctAnswer[index]).toLowerCase()
        );
        if (isCorrect) {
          note = currentExercise?.bareme;
        } else {
          console.log("Answer is Incorrect.");
        }
      } else {
        console.log("User Answer is empty or invalid.");
      }
    } else if (currentExercise?.type === "resolutionProbleme") {
      // Validate user's answers against correction_dp
      const isCorrect = currentExercise.correction_dp.every((correctStep, index) => {
        const userStep = userAnswer[index];

        // Normalize the expressions to remove spaces and ensure consistent format
        const normalizedUserStep = userStep?.replace(/\s+/g, '');
        const normalizedCorrectStep = correctStep?.replace(/\s+/g, '');

        // Evaluate the expressions using the custom evaluation function
        const userResult = evaluateExpression(normalizedUserStep);
        const correctResult = evaluateExpression(normalizedCorrectStep);

        return userResult === correctResult;
      });

      // Set the note based on the correctness of the solution
      note = isCorrect ? currentExercise?.bareme : 0;

      if (isCorrect) {
        console.log("Answer is Correct. Note:", note);
      } else {
        console.log("Answer is Incorrect.");
      }
    } else if (currentExercise.type === "missingNumbers") {
      // Ensure both arrays are of the same length
      if (Array.isArray(userAnswer) && userAnswer.length === correctAnswer.length) {
        const isCorrect = userAnswer.every((answer, index) => {
          const userValue = Number(answer);
          const correctValue = Number(correctAnswer[index]);
          return userValue === correctValue;
        });

        if (isCorrect) {
          note = currentExercise?.bareme;
        }
      } else {
        console.log(
          "User Answer length does not match the correct answer length."
        );
      }
    } else if (currentExercise.type === "operation") {
      console.log("Operation userAnswer: ", userAnswer);
      console.log("Operation correction: ", correctAnswer);

      const isCorrect = arraysAreEqual(userAnswer, correctAnswer);

      if (isCorrect) {
        note = currentExercise?.bareme;
        console.log("Answer is Correct. Note:", note);
      } else {
        console.log("Answer is Incorrect.");
      }
    }
    else {
      console.log("Type: Words to Numbers/Letters");
      console.log("User Answer (userAnswer):", userAnswer);
      if (selected?.length > 0 || textRecorded || userAnswer) {
        if (isEqual(correctAnswer, !userAnswer ? selected : [userAnswer])) {
          note = currentExercise?.bareme;
        }
      }
    }

    try {
      // Save the current exercise's note
      dispatch(setEscoreNiveau(note));
      dispatch(
        setEpreuveNote({
          epreuveId: currentExercise?.id,
          note: Math.round(note),
          niveau: diagnostiqueNiveaux?.data[currentLevel]?.niveau,
        })
      );

      // Prepare for the next exercise
      const nextIndex = current + 1;
      const isLastExercise =
        nextIndex === diagnostiqueNiveaux?.data[currentLevel]?.data?.length;

      if (isLastExercise) {
        // Redirect to the results page or finish component
        setEnd(true);
      } else {
        // Navigate to the next exercise
        navigation(`?niveau=${currentLevel}&epreuve=${nextIndex}`, { replace: true });

        // Reset state for the new exercise
        setUserAnswer(null);
        setSelected([]);
        setTextRecorded("");
        onSelecteFigure([]);
      }
    } catch (error) {
      console.error("Erreur lors du passage à l'exercice suivant :", error);
    }
  };

  // Custom evaluation function to handle expressions with 'x' as multiplication
  function evaluateExpression(expression) {
    const [operand1, operator, operand2] = expression?.split(/(\+|\-|\*|\x|\/|=)/);

    if (!operand1 || !operator || !operand2) {
      throw new Error('Invalid expression format');
    }

    switch (operator) {
      case '+':
        return Number(operand1) + Number(operand2);
      case '-':
        return Number(operand1) - Number(operand2);
      case '*':
      case 'x':
        return Number(operand1) * Number(operand2);
      case '/':
        return Number(operand1) / Number(operand2);
      default:
        throw new Error(`Invalid operator: ${operator}`);
    }
  }

  const checkAnswer = (value) => { };

  const handleCheck = (value) => {
    setUserAnswer(value);
  };

  /**
   * Filtrer les diagnostics par niveaux
   */
  useMemo(() => {
    if (diagnostiqueNiveauxData) {
      setDiagnostiqueNiveaux({
        ...diagnostiqueNiveaux,
        count: diagnostiqueNiveauxData?.count,
        data: [...diagnostiqueNiveauxData?.data].sort(
          (a, b) => getNumber(a.niveau) - getNumber(b.niveau)
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnostiqueNiveauxData]);

  /**
   * Mettre a jour le niveau et l'exercice courrant
   */
  useMemo(() => {
    setCurrentLevel(parseInt(searchParams.get("niveau")));
    setCurrent(parseInt(searchParams.get("epreuve")));
  }, [searchParams]);

  /**
   * Passage de niveau
   * @returns
   */
  const handleContinueNextLevel = async () => {
    // Verifier si on est a la fin du diagnosic complet
    const hasNextLevel = diagnostiqueNiveaux?.data?.length === currentLevel + 1;

    //
    const isBelowSeuil =
      diagnostiqueStore?.notes
        .filter(
          (note) =>
            note?.niveau === diagnostiqueNiveaux?.data[currentLevel]?.niveau
        )
        .reduce((acc, curr) => acc + curr?.note, 0) <
      diagnostiqueNiveaux?.data[currentLevel]?.seuil;

    if (hasNextLevel || isBelowSeuil) {
      try {
        const { score: scoreNiveau, ...res } = diagnostiqueStore;

        const response = await addDiagnostiqueEleveTentative({
          body: { ...res, status: "VALIDE", diagnosticId: parseInt(params.id) },
          token: csrfToken,
        });

        if (!response?.error) {
          setEnd(false);

          dispatch(
            setTentatives({
              diagnostique: diagnostiqueStore,
              eleveId: studentDemo?.id,
              diagnosticId: response?.data?.id,
              type: "MATHEMATIQUE",
              createdAt: new Date(Date.now()),
            })
          );

          navigation(`../analyse/${response?.data?.id}`, {
            replace: true,
          });
          return;
        }
        toast.error("Erreur lors de l'envoi");
      } catch (error) {
        console.error(
          "Erreur lors de la continuation vers le niveau suivant :",
          error
        );
      }
    } else {
      setEnd(false);
      navigation(`?niveau=${currentLevel + 1}&epreuve=0`, {
        replace: true,
        state: {},
      });
    }
  };

  return isLoading ? (
    <div className="h-[100dvh] w-full flex flex-col justify-center items-center">
      <Loader />
      <p>Chargement des exercices...</p>
    </div>
  ) : diagnostiqueNiveauxData?.count === 0 ? (
    <div className="h-[100dvh] w-full flex justify-center items-center flex-col gap-4">
      <p>Aucune question pour le moment</p>
      <Button radius={"md"} onClick={() => navigation(-1)}>
        retour
      </Button>
    </div>
  ) : (
    <Template
      footer={
        end || location.state?.end ? null : (
          <div
            style={{
              display: "flex",
              gap: 10,
              padding: "0 10px",
              marginTop: 5,
            }}
          >
            {diagnostiqueNiveaux?.count === 0 ? (
              <Button
                onClick={() => navigation(-1, { replace: true })}
                style={{ width: "100%" }}
                radius={"md"}
              >
                Retour
              </Button>
            ) : (
              !end && (
                <Button
                  onClick={handleNext}
                  style={{ width: "100%" }}
                  radius={"md"}
                  disabled={
                    userAnswer !== null ||
                      selected?.length > 0 ||
                      textRecorded ||
                      selecteFigure?.length > 0
                      ? false
                      : true
                  }
                >
                  Valider
                </Button>
              )
            )}
          </div>
        )
      }
    >
      {/* Word quizz */}
      {datas?.count === 0 ? (
        "Pas d'exercice"
      ) : end || location.state?.end ? (
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {isLoading || !diagnostiqueNiveaux?.data ? (
            <Loader />
          ) : (
            <Finish
              data={diagnostiqueNiveaux?.data[currentLevel]}
              globalNote={diagnostiqueNiveaux?.data[currentLevel]?.data?.reduce(
                (acc, cur) => acc + cur?.bareme,
                0
              )}
              score={diagnostiqueStore?.notes
                .filter(
                  (note) =>
                    note?.niveau ===
                    diagnostiqueNiveaux?.data[currentLevel]?.niveau
                )
                .reduce((acc, curr) => acc + curr?.note, 0)}
              note={diagnostiqueStore?.score}
              onRestart={() => window.location.reload()}
              seuil={diagnostiqueNiveaux?.data[currentLevel]?.seuil}
              onQuit={async () => {
                // return console.log({diagnostiqueStore});
                try {
                  await addDiagnostiqueEleveTentative({
                    body: { ...diagnostiqueStore },
                    token: csrfToken,
                  });
                  // console.log({ res });
                  navigation("/" + params?.eleveId + "/matiere", {
                    replace: true,
                  });
                } catch (error) {
                  console.log({ error });
                }
              }}
              onCountinue={handleContinueNextLevel}
              loading={addLoading}
              finish={
                diagnostiqueNiveaux?.data?.length === currentLevel + 1 ||
                diagnostiqueStore?.score <
                diagnostiqueNiveaux?.data[currentLevel]?.seuil
              }
            />
          )}
        </div>
      ) : (
        <MathGame
          // TODO: rename data to completDiagnostic
          completDiagnostic={diagnostiqueNiveaux && diagnostiqueNiveaux?.data ? diagnostiqueNiveaux?.data : []}
          eleve={eleve}
          studentDemo={studentDemo}
          userAnswer={userAnswer}
          setUserAnswer={setUserAnswer}
          currentLevel={currentLevel}
          current={current}
          selected={selected}
          result={result}
          handleCheck={handleCheck}
          checkAnswer={checkAnswer}
          handleAddWord={handleAddWord}
          handleRemoveWord={handleRemoveWord}
          randomeImage={progressImage[currentLevel]}
          handleGoBack={() => navigation(-1)}
          textRecorded={textRecorded}
          onTextRecorded={setTextRecorded}
          // Figure
          onSelecteFigure={onSelecteFigure}
        />
      )}
    </Template>
  );
}

export default ExerciceMathPage;
