import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// Créer une variable globale pour suivre le support PWA
window.isPWASupported = 'serviceWorker' in navigator;

// Enregistrer le service worker s'il est pris en charge
if (window.isPWASupported) {
  console.log("window.isPWASupported");
  
  serviceWorkerRegistration.register({
    onSuccess: (registration) => {
      console.log('Service Worker enregistré avec succès:', registration);
    },
    onUpdate: (registration) => {
      console.log('Nouvelle version disponible:', registration);
      // Envoyer un événement pour informer l'application qu'une mise à jour est disponible
      const event = new CustomEvent('swUpdateAvailable', { detail: registration });
      window.dispatchEvent(event);
    },
    onUnsupported: () => {
      console.log('Service Workers non supportés par ce navigateur');
      window.isPWASupported = false;
    },
  });
} else {
  console.log('Service Workers non supportés par ce navigateur');
}



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
