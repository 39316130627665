import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useGetUserInfo from "hooks/useGetUserInfo";
import { AuthProvider } from "context/AuthContext";
import { DrawerProvider } from "context/DrawerContext";
import { toast } from "react-toastify";
// import ModalInstall from "components/ModalInstall";
import Drawer from "components/Drawer";
import InstallPWA from "components/InstallPWA";

function Root({isPWASupported=true}) {
  const { userId } = useGetUserInfo();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lastUpdated, setLastUpdated] = useState(new Date());


  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const refreshPage = () => {
    setLastUpdated(new Date());
    toast({
      title: "Page rafraîchie",
      description: "Les données ont été mises à jour",
    });
  };

  const testOfflineCapabilities = () => {
    if (isOnline) {
      toast({
        title: "Test hors ligne",
        description: "Veuillez désactiver votre connexion internet pour tester les fonctionnalités hors ligne.",
      });
    } else {
      toast({
        title: "Mode hors ligne actif",
        description: "L'application fonctionne actuellement en mode hors ligne.",
      });
    }
  };

  return (
    <AuthProvider>
      <DrawerProvider>
        <div className="max-w-[500px] mx-auto overflow-hidden bg-red-300 relative">
          {userId === 1340 ? (
            <div className="fixed top-0 w-full left-0 px-2 py-[1px] text-[8px] flex justify-center font-bold text-white bg-danger z-50 opacity-90">
              DEMO TEST
            </div>
          ) : null}
          <Outlet />
          <Drawer />
          {/* <ModalInstall
            opened={opened}
            onInstall={handleInstall}
            onClosed={() => setOpened(false)}
          /> */}
          <InstallPWA />
        </div>
      </DrawerProvider>
    </AuthProvider>
  );
}

export default Root;
