
import React, { useState, useEffect } from 'react';
import { AlertCircle, RefreshCw } from 'lucide-react';
import { toast } from 'react-toastify';
import Button from './Button';

export const ServiceWorkerUpdater = ({ onUpdate }) => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);

    toast({
      title: 'Mise à jour disponible',
      description: 'Une nouvelle version est disponible. Cliquez pour mettre à jour.',
      action: (
        <Button onClick={reloadPage} variant="outline">
          <RefreshCw className="mr-2 h-4 w-4" />
          Mettre à jour
        </Button>
      ),
      duration: 0, // Don't auto-dismiss
    });
  };

  const reloadPage = () => {
    if (waitingWorker) {
      // Send a message to the waiting service worker to skip waiting
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }

    // Refresh the page to load the new version
    window.location.reload();
  };

  useEffect(() => {
    // Register the service worker update callback
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      window.navigator.serviceWorker.ready.then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                onSWUpdate(registration);
              }
            }
          };
        };
      });
    }
  }, []);

  return null; // The UI is handled by the toast notification
};

export default ServiceWorkerUpdater;
