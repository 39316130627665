import {useState, useEffect} from "react"
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "redux/app/Store";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "routes/app.routes";
import ErrorBoundary from "components/ErrorBoundary";
import CacheBuster from "./CashBuster";
import { toast, ToastContainer } from "react-toastify";
import ServiceWorkerUpdater from "components/ServiceWorkerUpdater";
import NetworkStatus from "components/NetworkStatus";

// console.log = () => {};

function App() {
  const [isPWASupported, setIsPWASupported] = useState(true);

  useEffect(() => {
    // Check for basic PWA support
    const hasServiceWorker = 'serviceWorker' in navigator;
    const hasManifest = !!document.querySelector('link[rel="manifest"]');
    setIsPWASupported(hasServiceWorker && hasManifest);
  }, []);
  useEffect(() => {
    if (!isPWASupported) {
      handleServiceWorkerUnsupported()
    }
  }, [isPWASupported])


  const handleServiceWorkerUpdate = () => {
    console.log("Service worker update available");
  };

  const handleServiceWorkerUnsupported = () => {
    console.log("Service worker not supported");
    // Only show this toast once per session
    if (!sessionStorage.getItem('pwaNoticeShown')) {
      toast.info("Votre appareil ne prend pas en charge toutes les fonctionnalités de l'application. Certaines fonctionnalités peuvent être limitées.", {autoClose: 9000});
      sessionStorage.setItem('pwaNoticeShown', 'true');
    }
  };
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return <p className="text-center mt-4">Chargement en cours...</p>;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ErrorBoundary>
                  <Routes isPWASupported={isPWASupported} />
                  <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <NetworkStatus />
                  {isPWASupported && (
                    <ServiceWorkerUpdater onUpdate={handleServiceWorkerUpdate} />
                  )}
                </ErrorBoundary>
              </PersistGate>
            </Provider>
          </>
        );
      }}
    </CacheBuster>
  );
}

export default App;
