import React from "react";
import { Card } from "./StyledComponent";
import Figure from "../Figure";
import TextToSpeech from "../TextSpeach";

const MathGameControls = ({
    type,
    choices,
    handleDrag,
    handleCheck,
    data,
    currentLevel,
    current,
    onSelecteFigure,
}) => {
    if (["wordToNumbers", "numberToWords", "missingNumbers", "completerEnonces"].includes(type)) {
        return null;
    }

    return (
        <div>
            {type === "figure" ? (
                <Figure data={data[currentLevel]?.data[current]} onSelecte={onSelecteFigure} />
            ) : null}
        </div>
    );
};

export default MathGameControls;
