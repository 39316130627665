import React, { useMemo, useState } from "react";
import youngGirl from "../../../assets/images/young_girl.png";

function TSQ({ onSelectChoice, data }) {
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(0);
  const [selected, setSelected] = useState([]);

  const [text, question] = useMemo(() => {
    const text = data?.consigne[0];
    const questions = JSON.parse(data?.correction_dp[0]);
    return [text, questions];
  }, [data]);

  const handleSelectionChoice = (item) => {
    setSelected([
      ...selected?.filter(
        (_item) => _item?.questionId !== question[current]?.id
      ),
      { ...item, questionId: question[current]?.id },
    ]);
    onSelectChoice([
      ...selected?.filter(
        (_item) => _item?.questionId !== question[current]?.id
      ),
      { ...item, questionId: question[current]?.id },
    ]);
  };

  return (
    <div className="flex-1 scale-95">
      {show ? (
        <div className="flex flex-col gap-4">
          <p className="p-2" onClick={() => setShow(false)}>
            {text?.substring(0, 100)}...
          </p>
          <div className="flex w-full gap-6">
            <img src={youngGirl} alt="young_girl" className="sm:h-18 h-20 " />
            <div className="flex flex-col gap-2">
              <div
                className={`speech-bubble px-4 py-2 border border-[#9992] bg-[#9991] text-center h-fit ${text.length > 2 ? "text-md" : "text-[1.8rem]"
                  } font-bold break-all shadow-lg`}
              >
                {question[current]?.titre}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            {question[current]?.responses?.map((item) => (
              <div
                key={item?.id}
                className={`p-4 ${selected.find((_item) => _item?.id === item?.id)
                  ? "bg-[rgba(0,255,0,0.5)] scale-100"
                  : "bg-[#9993] hover:bg-[#9995] scale-95"
                  } flex items-center justify-between cursor-pointer transition-all`}
                onClick={() => handleSelectionChoice(item)}
              >
                <p className="font-bold">{item?.titre}</p>
                {/* <p className="p-2 rounded-full bg-[green]">
                  <Check />
                </p> */}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex w-full gap-6">
          <img src={youngGirl} alt="young_girl" className="sm:h-48 h-40 " />
          <div className="flex flex-col gap-2">
            {/* <h3 className="px-3 rounded-full flex items-center justify-center bg-[#9993] font-bold self-end">
            {recorder?.context?.currentTime || 0} s
          </h3> */}
            <div
              className={`speech-bubble text-wrap px-4 py-2 border border-[#9992] bg-[#9991] text-center h-fit ${text.length > 2 ? "text-base" : "text-[1.8rem]"
                } font-bold`}
            >
              {text}
            </div>
          </div>
        </div>
      )}
      {show ? (
        <div className="flex items-center justify-center p-2 gap-4 mt-4  sm:w-full scale-90">
          {current > 0 && (
            <button
              className="bg-[#9992] font-bold py-3 px-6 rounded-full"
              onClick={() => setCurrent((prev) => prev - 1)}
            >
              Question precedente
            </button>
          )}
          {current + 1 === question?.length ? null : (
            <button
              className="bg-primary text-white font-bold py-3 px-6 rounded-full disabled:opacity-50"
              onClick={() => {
                setCurrent((prev) => prev + 1);
              }}
              disabled={
                selected.find(
                  (_item) => _item?.questionId === question[current]?.id
                )
                  ? false
                  : true
              }
            >
              Question suivante
            </button>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center p-2 mt-4 scale-90 sm:w-full">
          <button
            className={`${current > 0 ? "bg-warnong" : "bg-primary"} text-white font-bold py-3 px-6 rounded-full`}
            onClick={() => setShow(true)}
          >
            {current > 0 ? "Continuer" : "Repondre aux questions"}
          </button>
        </div>
      )}
    </div>
  );
}

export default TSQ;
