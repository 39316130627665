import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import fetch from "node-fetch";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    fetchFn: fetch
  }),
  refetchOnReconnect: true,
  keepUnusedDataFor: 604800,
  reducerPath: "genimiApi",
  endpoints: () => ({}),
});

export const ApiMananger = baseApi.enhanceEndpoints({
  addTagTypes: [
    "Coachs",
    "Parents",
    "Fiches",
    "Users",
    "Eleves",
    "Appels",
    "comprehensions",
    "Paiements",
    "Evaluations",
    "Niveaux",
    "Classes",
    "Diagnostics",
  ],
});
