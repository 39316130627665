import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getParentForm, setParentForm } from "redux/features/parentSlice";
import { NUMBER_VERIFIER_PATH } from "routes/api.path";

function useGetParent() {
  const dispatch = useDispatch();

  const parentForm = useSelector(getParentForm);
  // console.log(parentForm);
  useEffect(() => {
    // console.log("UserGetParent");
    if (parentForm?.phone) {
      (async () => {
        const resVerifyNumber = await axios.get(
          NUMBER_VERIFIER_PATH + parentForm?.phone + "/"
        );

        // return console.log(resVerifyNumber);
        if (
          resVerifyNumber?.status === 200 &&
          resVerifyNumber?.data?.message === "deja inscrit"
        ) {
          if (resVerifyNumber?.data?.user) {
            // console.log(resVerifyNumber?.data);
            dispatch(setParentForm({ ...resVerifyNumber?.data?.user }));
            // dispatch(addUserId(resVerifyNumber?.data?.user?.id));
            // dispatch(
            //   setNewParentCredit({ phone: resVerifyNumber?.data?.user?.phone })
            // );
            // toast.success("Connexion reussi !");
          } else {
            // toast.error("Erreur l'or de la verification");
          }
          return;
        }
      })();
    }
  }, [dispatch, parentForm?.phone]);

  return parentForm;
}

export default useGetParent;
