import React from "react";
import GameHeader from "./Maths/GameHeader";
import MathCardContainer from "./Maths/MathCardContainer";
import MathGameControls from "./Maths/MathGameControls";

const MathGame = ({
  completDiagnostic,
  currentLevel,
  current,
  selected,
  handleAddWord,
  handleRemoveWord,
  userAnswer,
  setUserAnswer,
  handleCheck,
  eleve,
  studentDemo,
  randomeImage,
  handleGoBack,
  onTextRecorded,
  textRecorded,
  onSelecteFigure,
}) => {
  const levelData = completDiagnostic[currentLevel]?.data;


  const handleDrop = (e, targetValue) => {
    e.preventDefault();
    const droppedValue = e.dataTransfer.getData("cardValue");

    // Vérifier si la valeur déposée correspond à la cible
    if (droppedValue === targetValue) {
      handleAddWord({ [targetValue]: droppedValue }); // Ajout dans les réponses utilisateur
      if (window.navigator.vibrate) {
        window.navigator.vibrate(100); // Effet de vibration
      }
    }
  };

  const handleDrag = (e, cardValue) => {
    e.dataTransfer.setData("cardValue", cardValue); // Définit la donnée transférée
  };


  const handleDragOver = (e) => {
    e.preventDefault(); // Permet le glisser-déposer
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <GameHeader
          handleGoBack={handleGoBack}
          eleve={eleve}
          current={current}
          data={completDiagnostic[currentLevel]}
          randomeImage={randomeImage}
          studentDemo={studentDemo}
        />
        <MathCardContainer
          data={levelData}
          current={current}
          item={levelData?.[current]}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleRemoveWord={handleRemoveWord}
          userAnswer={userAnswer}
          setUserAnswer={setUserAnswer}
          handleAddWord={handleAddWord}
          selected={selected}
        />
      </div>
      {levelData && (
        <MathGameControls
          type={levelData[current]?.type}
          choices={levelData[current]?.choices}
          consigne={levelData[current]?.consigne}
          correction_dp={levelData[current]?.correction_dp}
          userAnswer={userAnswer}
          handleCheck={handleCheck}
          handleDrag={handleDrag}
          selected={selected}
          onTextRecorded={onTextRecorded}
          textRecorded={textRecorded}
          data={completDiagnostic}
          currentLevel={currentLevel}
          current={current}
          onSelecteFigure={onSelecteFigure}
        />
      )}
    </div>
  );
};

export default MathGame;
