import styled from "styled-components";

export const InputAnswerArea = styled.input`
  border: 2px dashed #ccc;
  border-radius: 8px;
  width: 100px;
  padding: 10px 5px;
  text-align: center;
  margin-right: 4px;
`;

export const Card = styled.div`
  background: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
`;

export const AnswerArea = styled.div`
  border: 2px dashed #ccc;
  background: ${(props) => (props.correct === "false" ? "red" : "white")};
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  min-width: 50px;
`;

export const ContentWrapper = styled.div`
  /* Ajoutez des styles pour la disposition des éléments à l'intérieur */
  display: flex;
  flex-direction: column;
`;

export const InputAnswerAreaForLetters = styled.input`
  border: ${(props) =>
    props.disabled ? "3px dashed #333" : "2px dashed #ccc"};
  font-weight: ${(props) => (props.disabled ? "normal" : "800")};
  font-size: ${(props) => (props.disabled ? "12px" : "16px")};
  ${(props) => (props.correct === "false" ? "background-color: red;" : null)}
  border-radius: 8px;
  width: 220px;
  padding: 7px 5px;
  height: 60px;
  flex: 1;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
