import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";

const ButtonWrapper = styled.button`
  /* Styles communs */
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s;

  /* Hover */
  &:active {
    transform: translateY(1px); /* Une légère translation vers le bas */
  }

  /* Variantes */
  ${(props) =>
    props.variant === "primary" &&
    css`
      background-color: #007bff;
      color: white;

      &:hover {
        background-color: #0056b3;
      }
    `}

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background-color: #6c757d;
      color: white;

      &:hover {
        background-color: #545b62;
      }
    `}

  /* Tailles */
  ${(props) =>
    props.size === "sm" &&
    css`
      font-size: 12px;
      padding: 5px 10px;
    `}

  ${(props) =>
    props.size === "md" &&
    css`
      font-size: 15px;
    `}

  ${(props) =>
    props.size === "xs" &&
    css`
      font-size: 10px;
      padding: 3px 6px;
    `}

    /* Radius */
    ${(props) =>
    props.radius === "sm" &&
    css`
      border-radius: 10px;
    `}

    ${(props) =>
    props.radius === "md" &&
    css`
      border-radius: 20px;
    `}

    ${(props) =>
    props.radius === "xs" &&
    css`
      border-radius: 5px;
    `}

  /* État de chargement */
  ${(props) =>
    props.loading
      ? css`
          pointer-events: none;
          opacity: 0.7;
        `
      : null}
`;

const Button = ({
  children,
  variant,
  size,
  loading,
  radius,
  onClick,
  style,
  disabled = false,
  className
}) => {
  // const [isClicked, setIsClicked] = React.useState(false);

  const handleClick = () => {
    // setIsClicked(true);
    onClick && onClick();
  };

  return (
    <ButtonWrapper
      variant={variant}
      size={size}
      disabled={loading || disabled}
      //   loading={loading}
      radius={radius}
      onClick={handleClick}
      className={className}
      style={{ ...style, opacity: disabled || loading ? 0.5 : 1 }}
    >
      {loading ? "Loading..." : children}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["sm", "md", "xs"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  radius: PropTypes.oneOf(["sm", "md", "xs"]) || PropTypes.number,
};

Button.defaultProps = {
  variant: "primary",
  size: "md",
  radius: "xs",
  loading: false,
  onClick: null,
};

export default Button;
