import React, { useState, useEffect } from 'react';
import { Wifi, WifiOff } from 'lucide-react';
import { cn } from './../lib/utils';

export const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowAlert(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!showAlert) return null;

  return (
    <div
      className={cn(
        'fixed bottom-4 right-4 z-50 flex items-center rounded-lg p-4 shadow-lg transition-all duration-300',
        isOnline ? 'bg-white' : 'bg-warnong'
      )}
    >
      {isOnline ? (
        <>
          <Wifi className="mr-2 h-5 w-5 text-green-500" />
          <span className="text-sm font-medium text-green-800">Connexion internet rétablie</span>
        </>
      ) : (
        <>
          <WifiOff className="mr-2 h-5 w-5 text-yellow-500" />
          <span className="text-sm font-medium text-yellow-800">
            Vous êtes hors ligne. Certaines fonctionnalités peuvent être limitées.
          </span>
        </>
      )}
    </div>
  );
};

export default NetworkStatus;
