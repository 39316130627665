import React, { useState, useEffect, useCallback, useRef } from "react";
import { ArrowLeft, ArrowRight, Loader, PlayerPlay } from "tabler-icons-react";

const wordType = (word) => {
  const newWord = word.trim();
  if (newWord.split(" ").length > 1) return "Le groupe de mot";
  if (newWord.length === 2) return "La syllabe";
  if (newWord.length > 2) return "Le mot";
  return "La lettre";
};

const splitText = (text) => text?.split(/[\s-]+/).filter(Boolean) || [];

export const spliterText = (text) => {
  let newText = [];

  if (text?.includes("-")) {
    newText = text?.split("-");
  } else {
    newText = text?.split(" ");
  }

  if (Array.isArray(newText) && newText.length > 0 && newText?.includes("")) {
    newText = newText?.filter((item) => item);
  }

  do {
    newText = newText?.filter((item) => item || item !== " ");
  } while (
    newText && newText.length > 0 &&
    (newText?.includes("") || newText?.includes(" "))
  );

  return newText;
};

const TextToSpeech = ({
  text,
  current,
  handleValidate,
  selectedTextSpeach,
}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  const [value, setValue] = useState("");

  const [audioSrc, setAudioSrc] = useState(null);
  const audioRef = useRef(null);
  const words = splitText(text);

  useEffect(() => {
    setValue("");
    setCurrentWordIndex(0);
  }, [current]);

  const speakText = (word) => {
    const utterance = new SpeechSynthesisUtterance(word);
    utterance.lang = "fr-FR"; // French pronunciation
    utterance.rate = 0.8; // Slightly slower for better clarity
    utterance.pitch = 1.1; // Slightly higher pitch for better clarity
    utterance.volume = 1; // Maximum volume

    // Cancel any ongoing speech
    speechSynthesis.cancel();

    // Speak the new word
    speechSynthesis.speak(utterance);
  };

  useEffect(() => {
    if (audioSrc && audioRef.current) {
      audioRef.current.load();
      audioRef.current.play();
    }
  }, [audioSrc]);

  const getCurrentWord = (defaultIndex) => {
    const words = spliterText(text);
    if (defaultIndex) {
      return words[defaultIndex]?.trim() ?? "";
    }
    return words[currentWordIndex]?.trim() ?? "";
  };

  const handleNextWord = (e) => {
    e.preventDefault();
    if (currentWordIndex < spliterText(text).length) {
      if (value?.trim().length > 0) {
        handleValidate({ id: currentWordIndex, value: value?.trim() });
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
        setValue("");
        if (currentWordIndex + 1 < spliterText(text).length) {
          speakText(getCurrentWord(currentWordIndex + 1));
        }
      } else if (
        selectedTextSpeach?.find((item) => item?.id === currentWordIndex)?.value &&
        selectedTextSpeach?.find((item) => item?.id === currentWordIndex)?.value?.length > 0
      ) {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
        speakText(getCurrentWord(currentWordIndex + 1));
      } else {
        alert("Veuillez ecrire un mot");
      }
    }
  };

  const handlePrevWord = () => {
    if (currentWordIndex > 0) {
      setCurrentWordIndex((prevIndex) => prevIndex - 1);
      speakText(getCurrentWord(currentWordIndex - 1));
    }
  };

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <>
      {audioSrc && (
        <audio
          ref={audioRef}
          className="hidden"
          controls
          src={audioSrc}
        ></audio>
      )}
      <form className="w-full p-2 mt-6">
        <div className="text-center">
          <button
            onClick={() => speakText(getCurrentWord())}
            type="button"
            className="bg-primary rounded-full p-6 text-white cursor-pointer disabled:opacity-70"
            disabled={currentWordIndex === spliterText(text)?.length || isLoading}
          >
            {isLoading ? <Loader size={20} /> : <PlayerPlay size={20} />}
          </button>
        </div>

        <div className="flex items-center justify-between mt-4 gap-5">
          <button
            disabled={currentWordIndex <= 0 || isLoading}
            onClick={handlePrevWord}
            type="button"
            className="bg-secondary rounded-full py-2 px-4 text-white cursor-pointer disabled:opacity-40 w-full flex text-center items-center justify-center gap-x-4"
          >
            <ArrowLeft />
            Retour
          </button>
          <button
            onClick={handleNextWord}
            type="submit"
            disabled={
              currentWordIndex === spliterText(text)?.length || isLoading
            }
            className="bg-secondary rounded-full py-2 px-4 text-white cursor-pointer disabled:opacity-40 w-full flex text-center items-center justify-center gap-x-4"
          >
            Suivant
            <ArrowRight />
          </button>
        </div>

        <div>
          <input
            type="text"
            className="border-[1px] border-gray w-full p-2 rounded-lg outline-none text-center text-2xl h-[150px] mt-6"
            onChange={handleChange}
            placeholder="Ecrire ici"
            value={value}
            autoComplete={false}
            autoCorrect={false}
          />
        </div>
      </form>
    </>
  );
};

export default TextToSpeech;
