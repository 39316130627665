import React, { useContext, useEffect, useState } from "react";
import styles from "assets/styles.module.css";
import { DrawerContext } from "context/DrawerContext";
import {
  Camera,
  Logout,
  Moneybag,
  Phone,
  TestPipe,
  X,
} from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { useSelector } from "react-redux";
import { getParentForm } from "redux/features/parentSlice";
import useGetAssurance from "hooks/useGetAssurance";
import packageJson from "../../package.json";
// import { Audio } from "react-loader-spinner";

function Drawer() {
  const { opened, handleClose } = React.useContext(DrawerContext);
  const { logout } = useContext(AuthContext);
  // const {data: logoutUser} = useLogoutQuery()
  const parent = useSelector(getParentForm);
  const app = useGetAssurance();

  const navigate = useNavigate();

  return (
    <div
      className={styles.drawerContainer}
      style={{ right: opened ? "0vw" : "-100vw" }}
    >
      <div className="h-[100dvh] flex flex-col justify-between pb-6">
        <div className="">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2  p-2 cursor-pointer">
              <div className="bg-[#9993] rounded-full h-14 w-14 items-center flex justify-center">
                {/* <User /> */}
                <img
                  src="../../../assets/icons/faceBlack.png"
                  alt=""
                  className=""
                />
              </div>
              <div className="">
                <p className="font-bold">{parent?.fullName}</p>
                <p className="">{parent?.phone}</p>
              </div>
            </div>
            <div className="p-2 cursor-pointer" onClick={handleClose}>
              <X size={25} />
            </div>
          </div>
          <div className="flex flex-col items-start gap-3">
            <div className="h-[1px] bg-[#9993] w-full my-4"></div>
            {app === "Senassurance" ? null : (
              <div
                className="flex items-center gap-2  p-2 cursor-pointer"
                onClick={() => {
                  handleClose();
                  navigate("/payment");
                }}
              >
                <Moneybag />
                <p className="">Abonnement</p>
              </div>
            )}
            <div
              className="flex items-center gap-2  p-2 cursor-pointer"
              onClick={() => {
                handleClose();
                navigate("/contact-genimi");
              }}
            >
              <Phone />
              <p className="">Contacter Genimi</p>
            </div>
            {/* <div
              className="flex items-center gap-2  p-2 cursor-pointer"
              onClick={() => {
                handleClose();
                navigate("/camera");
              }}
            >
              <Camera />
              <p className="">Capture leçon</p>
            </div>
            <div
              className="flex items-center gap-2  p-2 cursor-pointer"
              onClick={() => {
                handleClose();
                navigate("/new");
              }}
            >
              <TestPipe />
              <p className="">Diagnostic Audio test</p>
            </div> */}
            {/* <div
              className="flex items-center gap-2  p-2 cursor-pointer"
              onClick={() => {
                handleClose();
                navigate("/audio");
              }}
            >
              <Camera />
              <p className="">Audio</p>
            </div> */}
          </div>
        </div>

        <div className="">
          <div className="flex items-center justify-center p-6">
            <img
              src="icons/genimi/group-logo.png"
              alt="logo"
              className="w-[200px] self-center"
            />
          </div>
          <div>Version {packageJson.version}</div>
          <div
            className="flex items-center gap-2 text-danger p-2 cursor-pointer"
            onClick={() => {
              handleClose();
              logout();
            }}
          >
            <Logout />
            <p className="">Se deconnecter</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drawer;
