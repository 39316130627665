import React, { useEffect, useState } from "react";
import { AnswerArea, Card, InputAnswerArea } from "./StyledComponent";

const Operation = ({
    item,
    data,
    current,
    setUserAnswer,
    handleDrag,
    handleDragOver,
}) => {
    const [consigneValues, setConsigneValues] = useState([]);

    // Initialize consigneValues based on item.consigne
    useEffect(() => {
        if (item?.consigne) {
            const values = item.consigne.map((consigneItem) => {
                try {
                    return JSON.parse(consigneItem);
                } catch (error) {
                    console.error("Failed to parse consigneItem:", consigneItem, error);
                    return { value: "", userValue: "" }; // Default fallback object
                }
            });
            setConsigneValues(values);
        } else {
            setConsigneValues([]);
        }
    }, [item]);

    const handleCardClick = (cardValue) => {
        console.log("Card clicked:", cardValue);

        // Update consigneValues to reflect the selected card value
        const updatedValues = consigneValues.map((consigne, index) => ({
            ...consigne,
            userValue: index === 0 ? cardValue : consigne.userValue, // Place the value in the first input
        }));

        console.log("Updated consigneValues:", updatedValues);

        setConsigneValues(updatedValues);
        setUserAnswer([cardValue]); // Directly set userAnswer to the selected card value
        console.log("User answer set to:", [cardValue]);
    };

    if (!item || !item.consigne || !Array.isArray(item.consigne)) {
        return <div>Invalid operation data</div>;
    }

    return (
        <div>
            <p className="py-4">Résolvez l'opération(cliquer sur la bonne réponse) :</p>
            <div className="flex gap-4 flex-wrap items-center justify-center">
                {consigneValues.map((consigne, index) => {
                    const isCorrectPlace = data[current]?.correction_dp.includes(consigne.value);

                    console.log(`Rendering consigne at index ${index}:`, consigne);

                    return isCorrectPlace ? (
                        <InputAnswerArea
                            key={index}
                            value={consigneValues[0].userValue || ""}
                            className="mb-3 p-2"
                            readOnly
                            placeholder=""
                        />
                    ) : (
                        <Card
                            key={index}
                        >
                            {consigne.value}
                        </Card>
                    );
                })}
            </div>
            <div className="flex gap-4 flex-wrap items-center justify-center mt-44">
                {item?.choices?.map((choice, index) => (
                    <Card
                        key={index}
                        onClick={() => handleCardClick(choice)}
                        style={{
                            borderColor: consigneValues.some((c) => c.userValue === choice)
                                ? "#003B7F"
                                : "#ccc",
                        }}
                    >
                        {choice}
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default Operation;
