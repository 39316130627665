import React from "react";
import { createPortal } from "react-dom";
import { X } from "tabler-icons-react";

function Modal({ opened, children, onClosed }) {
  if (!opened) {
    return;
  }

  return createPortal(
    <div className="fixed top-0 left-[50%] translate-x-[-50%] bg-[gray] bg-opacity-50 z-50 max-w-[500px] w-full h-screen flex items-center justify-center p-5"
      data-testid="modal"
    >
      <div className="w-full h-auto p-4 rounded-lg bg-[white] flex flex-col relative">
        <div
          className="absolute right-2 cursor-pointer p-1 top-2"
          onClick={onClosed}
        >
          <X color="red" />
        </div>
        <div className="">{children}</div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default Modal;
