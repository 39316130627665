import React, { useState, useEffect } from 'react';
import { Download, Info, X } from 'lucide-react';
import Button from './Button';
import {toast} from "react-toastify"

export const InstallPWA = () => {
  const [installPrompt, setInstallPrompt] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [isPWASupported, setIsPWASupported] = useState(true);
  const [closeInstall, setCloseInstall] = useState(false)

  useEffect(() => {
    // Check if app is already installed
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsAppInstalled(true);
    }

    // Check for PWA support
    const checkPWASupport = () => {
      const hasServiceWorker = 'serviceWorker' in navigator;
      const hasManifest = !!document.querySelector('link[rel="manifest"]');
      // iOS doesn't support beforeinstallprompt but can still use PWAs
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      // Return true if service worker is supported and manifest exists
      // OR if it's iOS (which has partial PWA support)
      return (hasServiceWorker && hasManifest) || isIOS;
    };

    setIsPWASupported(checkPWASupport());

    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      // Prevent Chrome 76+ from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later
      console.log({e});

      setInstallPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Listen for app installed event
    window.addEventListener('appinstalled', () => {
      setIsAppInstalled(true);
      setInstallPrompt(null);
      toast({
        title: "Application installée",
        description: "L'application a été installée avec succès",
      });
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {

    if (!installPrompt) {
      // Show instructions for iOS or other browsers without beforeinstallprompt support
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        toast.info("Installation sur iOS: Appuyez sur 'Partager' puis 'Sur l'écran d'accueil' pour installer l'application.", {autoClose: 8000})
      } else if (isPWASupported) {
        toast.info("Utilisez le menu de votre navigateur pour installer cette application.");
      } else {
        toast.info("Votre appareil ne prend pas en charge l'installation d'applications web.");
      }
      return;
    }

    // Show the prompt
    await installPrompt.prompt();

    // Wait for the user to respond to the prompt
    const choiceResult = await installPrompt.userChoice;

    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }

    // Clear the saved prompt
    setInstallPrompt(null);
  };

  const handleCloseInstall = (e) => {
    e.stopPropagation()
    setCloseInstall(true)
  }

  // Always show the install button for user experience consistency
  // But we'll adjust the behavior based on support
  return (
    <div className="fixed bottom-4 left-4 z-50 bg-white border rounded">
      {(!isAppInstalled && !closeInstall) && (
        <Button
          onClick={handleInstallClick}
          className="flex items-center"
          variant={isPWASupported ? "default" : "outline"}
        >
          {isPWASupported ? (
            <>
              <Download className="mr-2 h-4 w-4" />
              Installer l'application
            </>
          ) : (
            <>
              <Info className="mr-2 h-4 w-4" />
              Utiliser dans le navigateur
            </>
          )}
          <X onClick={handleCloseInstall} size={24} style={{marginLeft:"10px"}} />
        </Button>
      )}
    </div>
  );
};

export default InstallPWA;
