import Button from "components/Button";
import React from "react";
import Divider from "../pages/matiere/components/Divider";
import confetti from "canvas-confetti";

const duration = 15 * 1000;
const animationEnd = Date.now() + duration;
const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function Finish({
  score,
  note,
  onQuit,
  seuil,
  onCountinue,
  data = {},
  loading = false,
  finish,
  globalNote,
}) {
  if (score >= seuil) {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });

    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      setTimeout(() => {
        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        });
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        });
      }, 1000);
    }, 250);
  }

  // console.log(seuil);

  return (
    <div className="h-full w-full flex flex-col items-center justify-between pb-2">
      <div className="h-full w-full flex flex-col">
        <div className="flex items-center justify-center p-6">
          <img
            src={`../../../../assets/kids/${
              score < seuil ? "perdu2.png" : "finish1.png"
            }`}
            alt="finish"
            className="w-2/3"
          />
        </div>

        <h1 className="text-center p-4 font-bold text-md text-primary">
          {data?.niveau} TERMINE
        </h1>

        <Divider />

        <div className="text-center font-bold text-3xl">
          <h1 className="">
            {score} / {globalNote}
          </h1>
        </div>

        <span className="text-center font-bold mt-3 text-lg">
          seuil : {seuil}
        </span>
        <div className="p-3 text-center text-sm">
          {score < seuil
            ? "Oups ! Vous n'avez pas réussi le diagnostic"
            : "Bravo ! Vous avez réussi le diagnostic"}
        </div>
      </div>
      <div className="w-full gap-2 flex px-4">
        {seuil < data?.seuil ? null : (
          <Button
            style={{ backgroundColor: "#77B8FE", flex: 1, height: 40 }}
            radius={"md"}
            onClick={onQuit}
            loading={loading}
            disabled={loading}
          >
            Quitter
          </Button>
        )}
        <Button
          style={{ backgroundColor: "#003880", flex: 1, height: 40 }}
          radius={"md"}
          onClick={onCountinue}
          disabled={loading}
        >
          {finish ? "Terminer" : "Continuer"}
        </Button>
      </div>
    </div>
  );
}

export default Finish;
