import React, { useState, useEffect } from "react";
import MissingNumbers from "./MissingNumbers";
import WordToNumbers from "./WordToNumbers";
import TrouverLInconnu from "./TrouverLInconnu";
import Operation from "./Operation";
import CompleterEnonces from "./CompleterEnonces";
import ResolutionProbleme from "./ResolutionProbleme";
import NumberDictation from "./NumberDictation";
import WinWil from "./WinWil";
import Od from "./Od";

const MathCardContainer = ({
    data,       // Array of diagnostic data
    current,
    item,
    handleAddWord,
    userAnswer,
    setUserAnswer,
}) => {
    const [consigneValues, setConsigneValues] = useState([]);

    // Initialize consigneValues based on item.consigne
    useEffect(() => {
        if (!item || !item.consigne) {
            setConsigneValues([]);
            return;
        }

        if (item.type === "missingNumbers") {
            setConsigneValues(
                item.consigne.map((c) => {
                    const parsedItem = JSON.parse(c);
                    return parsedItem.value !== "" ? { value: parsedItem.value, userValue: "" } : { value: "", userValue: "" };
                })
            );
        } else if (item.type === "operation") {
            setConsigneValues([]);
        } else {
            setConsigneValues(
                item.consigne.map((c) => {
                    console.log({ c });
                    return typeof c === "string" ? { value: "", userValue: "" } : c;
                })
            );
        }
    }, [item]);


    // Update userAnswer whenever consigneValues changes
    useEffect(() => {
        if (!item?.type || item?.type === undefined) {
            return <div>Exercice introuvable</div>
        }
        if (item?.type !== "operation") {
            if (item?.type === "missingNumbers") {
                setUserAnswer(
                    consigneValues.map((c) => {
                        return c?.userValue || c?.value || ""
                    })
                );
            } else {
                setUserAnswer(
                    consigneValues.map((c) => {
                        return c?.userValue || c?.value || ""
                    })
                )?.filter((v) => v !== "")
            }

        }
    }, [consigneValues, item, setUserAnswer]);

    if (!item) return <div>Exercice introuvable</div>;

    switch (item.type) {
        case "missingNumbers":
            return (
                <MissingNumbers
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                    handleAddWord={handleAddWord}
                />
            );

        case "wordToNumbers":
        case "numberToWords":
            return (
                <WordToNumbers
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                />
            );

        case "trouverLInconnu":
            return (
                <TrouverLInconnu
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                />
            );

        case "operation":
            return (
                <Operation
                    item={item}
                    data={data}
                    current={current}
                    userAnswer={userAnswer}
                    setUserAnswer={setUserAnswer}
                    handleDrag={(e, value) => e.dataTransfer.setData("cardValue", value)}
                    handleDragOver={(e) => e.preventDefault()}
                />
            );

        case "completerEnonces":
            return (
                <CompleterEnonces
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                    handleAddWord={handleAddWord}
                />
            );

        case "resolutionProbleme":
            return (
                <ResolutionProbleme
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                    handleAddWord={handleAddWord}
                />
            );

        case "numberDictation":
            return (
                <NumberDictation
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                />
            );

        case "oc":
        case "od":
            return (
                <Od
                    item={item}
                    setUserAnswer={setUserAnswer}
                />
            );

        case "win":
        case "wil":
            return <WinWil item={item} />;



        default:
            return <div>Type de diagnostic non pris en charge</div>;
    }
};

export default MathCardContainer;
